import Swiper from 'swiper/bundle'
import {mobileCheck} from '../utils'
import $ from 'jquery'

export default class CategoriesSlider {
  // An example without jquery
  constructor($el) {
      // console.log('CategoriesSlider Slider');
      this.$el = $el;

      this._init();
      
  }

  _init() {
    // not creating slider in mobile    
    if (!mobileCheck()) {
      //const pagination = this.$el.querySelector('.swiper-pagination')
      const prev = this.$el.querySelector('.swiper-button-next')
      const next = this.$el.querySelector('.swiper-button-prev')
      this.cat_slider = new Swiper(this.$el, {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
          nextEl: prev,
          prevEl: next,
        },
        speed: 500,        
        // autoplay: {
        //   delay: 4000,          
        // },
        breakpoints: {
          640: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 3
          },
          990: {
            slidesPerView: 4
          },
          1280: {
            slidesPerView: 5
          },
          1600: {
            slidesPerView: 6
          }
        }
        
      });
    }

    
    const btn_cat = $(this.$el).find('.btn-cat');
    btn_cat.hover(function(){          
      $(this).parents('.item-wrapper').find('.cat-wrapper').addClass('hover');      
    },
    function(){
      $(this).parents('.item-wrapper').find('.cat-wrapper').removeClass('hover');
    });
  }
}