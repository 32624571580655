import Parallax from 'parallax-js' 

export default class BgAnims {
  // An example without jquery
  constructor() {            
      this._init();    

  }
  _init() {      
    var sceneProduct = document.getElementById('scene-product');
    if (sceneProduct) {
      var leavesProduct = new Parallax(sceneProduct);
    }

    var sceneReceita = document.querySelector('.scene-receita');
    if(sceneReceita) {
      new Parallax(sceneReceita); 
    }

    var scenePAge = document.querySelector('.scene-page');
    if(scenePAge) {
      new Parallax(scenePAge); 
    }

  }
}