import Swiper from 'swiper/bundle';
//import 'swiper/swiper-bundle.min.css';

export default class CardsSlider {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;

      this._init();
  }
  _init() {    
    const pagination = this.$el.querySelector('.swiper-pagination')
    const prev = this.$el.querySelector('.swiper-button-next')
    const next = this.$el.querySelector('.swiper-button-prev')
    const container = this.$el.querySelector('.swiper-container')

    this.card_slider = new Swiper(container, {
      spaceBetween: 10,
      watchOverflow: true,
      loop: false,
      pagination: {
        el: pagination,
      },      
      speed: 500,            
      autoplay: {
        delay: 4000,        
      },
      navigation: {
        nextEl: prev,
        prevEl: next,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1.60,
          centeredSlides: true,
          initialSlide: 1,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 1.50,
          centeredSlides: true,
          initialSlide: 1,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 2,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 2,
        },
        970: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1600: {
          slidesPerView: 4,
          spaceBetween: 20,
        }
      }
    });
  }
}