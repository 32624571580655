
import ToOrganize from '../js/blocks/ToOrganize'
import Example from '../js/blocks/Example'
import Video from '../js/blocks/Video';
//import Example from '../js/blocks/Example'
import HeroSlider from '../js/blocks/HeroSlider'
import ReviewSlider from '../js/blocks/ReviewSlider'
import CategoriesSlider from '../js/blocks/CategoriesSlider'
import CardsSlider from '../js/blocks/SliderCards'
import ProductsFilter from '../js/blocks/ProductsFilter'
import ProductsSlider from '../js/blocks/ProductsSlider'
import Menu from '../js/blocks/Menu'
import Search from '../js/blocks/Search'
import PageSlider from '../js/blocks/PageSlider'

import Modal from '../js/components/Modal'
import SiteReviewsForm from '../js/components/SiteReviewsForm'
import GoTop from '../js/components/GoTop'
import BmiCalculator from '../js/components/BmiCalculator'
import FoodWeel from '../js/components/FoodWeel'
import BgAnims from '../js/components/BgAnims'


import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery';


export default {
  init() {
    // JavaScript to be fired on all pages
    //console.log('common');

    AOS.init({
      once: true,
      startEvent: 'load',
    });
    this._initToorganize();
    this._initExample();
    this._initVideo();
    this._initHeroSlider();
    this._initCategoriesSlider();
    this._initCardsSlider();
    this._initProductsFilter();
    this._initReviewSlider();
    this._initProductsSlider();
    this._initMenu();
    this._initGoTop();
    this._initModal();    
    this._initSiteReviewsForm();    
    this._initSearch()
    this._initPageSlider();
    this._initBmiCalculator();
    this._initFoodWeel();

    this._productResizePosition();
    this._productSetPosition();
    new BgAnims();

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },

  _initToorganize() {
    new ToOrganize();
  },

  _initVideo() {
    new Video();
  },

  _initExample() {
    // const $exampleEl = document.querySelector(".example");
    // new Example($exampleEl);
  },

  _initHeroSlider() {
    const $sliderItems = [...document.querySelectorAll('.hero-slider')];
    $sliderItems.forEach($sliderItem => {
        new HeroSlider($sliderItem)
    });
  },

  _initCategoriesSlider() {
    const $sliderItems = [...document.querySelectorAll('.slider-categories')];
    $sliderItems.forEach($sliderItem => {
        new CategoriesSlider($sliderItem)
    });
  },

  _initProductsFilter() {
    new ProductsFilter();
  },

  _initCardsSlider() {
    const $sliderItems = [...document.querySelectorAll('.container-cards')];
    $sliderItems.forEach($sliderItem => {
        new CardsSlider($sliderItem)
    });
  },

  _initProductsSlider() {
    const $sliderItems = [...document.querySelectorAll('.products-slider-block')];
    $sliderItems.forEach($sliderItem => {
        new ProductsSlider($sliderItem)
    });
  },

  _initGoTop() {
    const $goTopItems = [...document.querySelectorAll('.go-top')];
    $goTopItems.forEach($item => {
        new GoTop($item);
    });
  },

  _initModal() {
    const $modal = [...document.querySelectorAll('.modal')];
    $modal.forEach($item => {
        new Modal($item);
    });
  },

  _initSiteReviewsForm() {
    const $siteReviewsForm = [...document.querySelectorAll('.glsr-form')];
    $siteReviewsForm.forEach($item => {
        new SiteReviewsForm($item);
    });
  },

  _initReviewSlider() {
    const $sliderItems = [...document.querySelectorAll('.reviews-slider-block')];
    $sliderItems.forEach($sliderItem => {
        new ReviewSlider($sliderItem)
    });
  },

  _initMenu() {
    const $menuItems = [...document.querySelectorAll('.top-banner')];
    $menuItems.forEach($item => {
        new Menu($item);
    });
  },

  _initSearch() {
    const $menuSearch = [...document.querySelectorAll('.top-banner')];
    $menuSearch.forEach($search => {
        new Search($search);
    });
  },

  _initPageSlider() {
    const $sliderItems = [...document.querySelectorAll('.pages-slider-block')];
    $sliderItems.forEach($sliderItem => {
        new PageSlider($sliderItem)
    });
  },

  _initBmiCalculator() {
    const $calculator = [...document.querySelectorAll('.calculator-wrapper')];
    $calculator.forEach($item => {
        new BmiCalculator($item);
    });
  },

  _initFoodWeel() {
    const $foodWeel = [...document.querySelectorAll('.food-weel')];
    $foodWeel.forEach($item => {
        new FoodWeel($item);
    });
  },

  _productResizePosition() {
    $(window).on('resize', (e) => {
      this._productSetPosition();
    })
  },

  _productSetPosition() {
    if($(window).width() > 767) {
      let breadcrumb = $('.woocommerce-breadcrumb').outerHeight( true );
      $('.single-product-container').css('margin', `-${breadcrumb}px auto 0px`)
      $('.single-product-wrapper .bg-container').css('margin', `-${breadcrumb}px auto 0px`)
    } else {
      $('.single-product-container').css('margin', '40px auto 0px')
    }
  }
};
