
import $ from 'jquery'

export default class Modal {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;
      this._init();
      
  }
  _init() {  
    var modal = $(this.$el);
    var openBtn = $('.btn.modal-open');
    var closeBtn = modal.find(".closeBtn");


    openBtn.on('click',function() {     
      modal[0].classList.remove('hidden');
    });

    closeBtn.on('click',function() {      
      modal[0].classList.add('hidden');
    });


    window.onclick = function(event) {
      
      if (event.target.classList.contains('modal')) {
        modal[0].classList.add('hidden');
      }
}
  }
}