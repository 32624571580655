import $ from 'jquery'

export default class Video {
  constructor() {
      this.elSelector = $('.video');
      this._video();
  }
  _video() {
    this.elSelector.click(function(e) {
      e.preventDefault();

      const button = $(this).find('.video-button');
      button.addClass('hide');

      const img = $(this).find('img');
      img.addClass('hide');

      const iframe = $(this).find('iframe');
      iframe.addClass('index');
      let videoURL = iframe.attr("data-src");
      iframe.attr('src', videoURL);

    });
  }
}