import Swiper from 'swiper/bundle';

export default class HeroSlider {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;
      this._init()
  }
  _init() {    
    const pagination = this.$el.querySelector('.swiper-pagination')
    const prev = this.$el.querySelector('.swiper-button-next')
    const next = this.$el.querySelector('.swiper-button-prev')
    this.slider = new Swiper(this.$el, {      
      pagination: {
        el: pagination,
      },
      speed: 500,      
      autoplay: {
        delay: 4000,        
      },
      navigation: {
        nextEl: prev,
        prevEl: next,
      }        
    });    
          
  }
}