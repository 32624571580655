import $ from 'jquery'

export default class Menu {
  constructor($el) {      
    this.$el = $el;
    this._init();
  }
  _init() {  
    $('#main-menu .menu-item').each((ind, ele) => {
      $(ele).children('a').attr({
        'data-action':'move-curtain',
        'data-menu':ind+1,
      });
    });

    $('#mobile-menu .menu-item-has-children').children('a').attr('data-action', 'show-dropdown');

    $('[data-action="move-curtain"]').click((e) => {
      e.stopPropagation()
      this.toggleMenu(e.currentTarget)
    })

    $('[data-action="show-dropdown"]').click(function() {
      if ($(this).hasClass('show')) {
        $(this).removeClass('show');
        $(this).closest('li').children('.sub-menu').slideUp( 450 );
      } else {
        $(this).addClass('show');
        $(this).closest('li').children('.sub-menu').slideDown( 450 );
      }
      // if ($(this).hasClass('show')) {
      //   $(this).removeClass('show');
      //   $(this).closest('li').children('.sub-menu').slideUp( 450 );
      // } else {
      //   $('.sub-menu').slideUp(450);
      //   $('[data-action="show-dropdown"]').removeClass('show');
      //   $(this).addClass('show');
      //   $(this).closest('li').children('.sub-menu').slideDown( 450 );
      // }
    });

    $( window ).resize((e) => {
      if($(window).width() == 767 || $(window).width() == 768 && $(".menu-curtain").hasClass("open-menu")) {
        $(".menu-curtain").removeClass("open-menu")
        $("body").removeClass("open-menu")
      }
    });
  }

  toggleMenu(element) {
    if($('.go-search').length !== 0) {
      $('.go-search').removeClass('go-search')
      $(".menu-curtain").toggleClass("open-menu");
    } else {
      $(".menu-curtain").toggleClass("open-menu");
      $("body").toggleClass("open-menu");
    }
    
    let menuData = $(element).data('menu');
    $('[data-submenu]').css('display', 'none')
    if(menuData !== undefined) {
      let submenu = $(`[data-submenu="${menuData}"]`)
      $(submenu).css('display', 'block');
    }

    if ($("body").hasClass("open-menu")) {
      $("body.open-menu").css("top", `-${this.pageTop}px`)
    } else {
      window.scrollTo(0, parseInt($("body").css("top").slice(1, -2)))
      $("body").css("top", "unset")
    }
  }
}