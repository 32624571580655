import $ from 'jquery'

export default class Search {
  constructor($el) {      
    this.$el = $el;

    this._init();
  }
  _init() {  
    $('[data-action="search"]').click((e) => {
      e.stopPropagation()
      $(".search-curtain").toggleClass("go-search");
      $("header .bg-opac").toggleClass("go-search");
      $(".right-header").toggleClass("go-search");
      $(".right-header .magnifier").toggleClass("go-search");
      $(".right-header .close-search").toggleClass("go-search");
      $("body").toggleClass("open-menu");

      if ($("body").hasClass("open-menu")) {
        $("body.open-menu").css("top", `-${this.pageTop}px`)
      } else {
        window.scrollTo(0, parseInt($("body").css("top").slice(1, -2)))
        $("body").css("top", "unset")
      }
    })

    $( window ).resize((e) => {
      if($(window).width() < 768 && $(".search-curtain").hasClass("go-search")) {
        $('.go-search').removeClass('go-search')
        $("body").removeClass("open-menu")
      }
    });


    $('.search-bar').on('keyup', 'input', function(e) {
      // console.log(e.target.value);
      let search = e.target.value.trim();
      let $el_result = $(e.target).parents('.search-bar').find('.search-result');
      $el_result[0].innerHTML = '';

      if (search.length < 2) {
        document.querySelector('.search-result').innerHTML = "";
        return;
      }
      const type = $(e.target).siblings('[name=type]').val();
      let data = {
        'action': 'searching',
        'text': search,   
        'type':  type,
        'nonce': $("[name='csrf-token']").attr("content"),    
      };  

      
      $.ajax({
        url : window.ajaxurl,
        data : data,
        dataType: 'json',        
        type : 'POST',
        success : function( data ){

            if( data ) {
                // console.log(data);
                // let response = data.result;
                // console.log('SEARCHING RESPONSE: ' + response);

                $el_result[0].innerHTML = data.result_html;

            }            
        },
      });
    
    });
  }
}