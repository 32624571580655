import $ from 'jquery'

export default class GoTop {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;
      this._init();
      
  }
  _init() {  
    $(this.$el).click(function() {      
      $('html,body').animate({ scrollTop: 0 }, 400);
      return false;
    });
  }
}