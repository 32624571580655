
import $ from 'jquery'

export default class SiteReviewsForm {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;
      this._init();
      
  }
  _init() {  
    
    const form = $(this.$el);
    const showConditions = form.find('.show-conditions');
    const conditions = form.find('.conditions');

    showConditions.on('click',function() {     
      showConditions.toggleClass('open');
      conditions.toggleClass('hidden');
    });
  }
}