import {fetchPosts} from '../utils'
import $ from 'jquery'

export default class ToOrganize {
  constructor() {      
    this.elSelect = $('.select-box');
    this.elWrapper = $('.list-wrapper');
    this.init();
    this._auxFunc(); 
  }

  init() {
    this.elSelect.change(function() {
      window.select_filter.current_page = 1;
      fetchPosts();
    });
    
    this.elWrapper.on('click', '#next, #next-big', function(e) {
      e.preventDefault();
      window.select_filter.current_page+=1;
      setTimeout(() => {
        fetchPosts();
      }, 300);
    });
    this.elWrapper.on('click', '#prev, #prev-big', function(e) {
      e.preventDefault();
      window.select_filter.current_page-=1;
      setTimeout(() => {
          fetchPosts();
      }, 300);
    });
    this.elWrapper.on('click', '.current-page' ,function(e) {
      e.preventDefault();
      if($(e.target).hasClass('active-page')){
          e.stopPropagation();
      } else {
          $('.current-page').removeClass('active-page');
          window.select_filter.current_page = 0 + $(e.target).attr('data-page');
          setTimeout(() => {
              fetchPosts();
          }, 300);
      }
    });
  }

  _auxFunc() {
    // console.log('JS to organize...');
  }

}

