
//import Rellax from 'rellax'
import Parallax from 'parallax-js' 
import {mobileCheck} from '../js/utils'

export default {
  init() {    

    //this._initParallaxes();

    // var rellaxFolhas = new Rellax('.bg-folhas-lg');
    // var rellaxFolhasSm = new Rellax('.bg-folhas-sm');
    // var rellaxFolhasMeio = new Rellax('.folhas-meio-lg');
    // var rellaxFolhasMeioSm = new Rellax('.folhas-meio-sm');
    // var rellaxSemente = new Rellax('.bg-sementes');
    // var rellaxFormasMeio = new Rellax('.formas-meio');
    // var rellaxFormaDta = new Rellax('.forma-drta');
    // var rellaxFormaDta2 = new Rellax('.forma-drta2');
    // var rellaxFolhasBaixo= new Rellax('.folhas-baixo');    


    var sceneFront = document.getElementById('scene-front');
    if (sceneFront) {
      var parallaxInstance = new Parallax(sceneFront);  
    }    

    var sceneBackS = document.getElementById('scene-back');
    if (sceneBackS) {
      var parallaxInstanceBack = new Parallax(sceneBackS);  
    }
    
    
  },

  
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },


  // _initParallaxes() {
  //   var lastScrollTop = 0;
  //   // var initialTopfolhas = getComputedStyle(document.getElementsByClassName('bg-folhas')[0])
  //   //                       .getPropertyValue('top')
  //   //                       .match(/\d+/);

  //   // let initialTopFolhasMeio = getComputedStyle(document.getElementsByClassName('folhas-meio')[0])
  //   //                       .getPropertyValue('top')
  //   //                       .match(/\d+/);  

  //   let initialTopfolhas = -58;
  //   let initialTopFolhasMeio = 757;  


  //   console.log('Initial top folhas: ' + initialTopfolhas);
  //   console.log('Initial top folhas meio: ' + initialTopFolhasMeio);


  //   window.addEventListener('scroll', function() {
  //     var scrollY = window.scrollY;
  //     let folhasTop = document.getElementsByClassName('bg-folhas')[0];      
  //     let folhasMeio = document.getElementsByClassName('folhas-meio')[0];
                      

  //     let addValue = Math.round( 0.5 * scrollY );     
  //     let addFolhasMeio =  Math.round( 0.2 * scrollY );

  //     let newTopFolhas = parseInt(initialTopfolhas) + parseInt(addValue);
  //     let newTopFolhasMeio = parseInt(initialTopFolhasMeio) - parseInt(addFolhasMeio);

  //     var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
  //     if (st > lastScrollTop){
  //         // downscroll code
  //     } else {
  //         // upscroll code                    
  //     }
      
  //     lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

  //     folhasTop.style.top = newTopFolhas + 'px';
  //     folhasMeio.style.top = newTopFolhasMeio + 'px';
      
  //     console.log('Add value: ' + addValue);
  //     console.log('Scroll: ' + scrollY);      
  //     console.log('NEW Top Folhas: ' + newTopFolhas);
  //     console.log('NEW Top Folhas Meio: ' + newTopFolhasMeio);

  //   });
  // },
  
};
