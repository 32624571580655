import $ from 'jquery'

export default class BmiCalculator {
  // An example without jquery
  constructor($el) {      
      this.$el = $el;
      this._init();
  }
  _init() { 
    let gender;
    let activity;
    const btn = $('.calculator-wrapper form');

    $('.btn-gender a').click((event) => {
      event.preventDefault();
      gender = this.checkSelection(event.target)
    })

    $('.btn-activity a').click((event) => {
      event.preventDefault();
      activity = this.checkSelection(event.target)
    })

    $(btn).on('submit', (event) => {
      event.preventDefault();

      if( gender == true && activity==true ) {
        $('.error').hasClass('js-error') ? $('.error').removeClass('js-error').fadeTo( 200, 0 ).slideUp(200) : null;
        // const age_value = $('.calculator-wrapper input[type=number][name=age]').val();
        const weight_value = $('input[type=number][name=weight]').val();
        const height_value = $('input[type=number ][name=height]').val();
        
        let bmi = Math.round(weight_value / (height_value * height_value));
        setTimeout(() => {
          $('.calculator-result h3 span').text(' ' + bmi);
          $('.calculator-result p span strong').text(' ' + this.idealWeight(bmi));
          $('.calculator-result').slideDown().fadeTo( "slow", 1 );
        }, 250);
      } else {
        $('.error').addClass('js-error').slideDown(200).fadeTo( 200, 1 );
      }
    });
  }

  checkSelection(selec) {
    if($(selec).hasClass('js-calc')) {
      $(selec).removeClass('js-calc');
    } else {
      $(selec).siblings().removeClass('js-calc');
      $(selec).addClass('js-calc');
      return true;
    }
  }

  idealWeight(value) {
    let text = '';
    if(value < 18.50) {
      text = 'Abaixo Peso Ideal';
    } else if (value >= 19.50 && value < 25) {
      text = 'Peso Normal';
    } else if (value >= 25 && value < 30) {
      text = 'Acima Peso Ideal';
    } else if (value >= 30 && value < 35) {
      text = 'Obesidade Grau I';
    } else if (value >= 35 && value < 40) {
      text = 'Obesidade Grau II';
    } else if (value >= 40) {
      text = 'Obesidade Grau III';
    } 

    return text;
  }

}