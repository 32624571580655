import $ from 'jquery'

export default class ProductsFilter {
  constructor() {
      this.elWrapper = $('.products-wrapper');
      this.init()
      this.moreProducts = this.moreProducts.bind(this);
      this.productPerPage = this.productPerPage.bind(this);
      this.productsFiltering = this.productsFiltering.bind(this);
      this._bindBtnFilter = this._bindBtnFilter.bind(this);
      
  }

  init() {
    this.moreProducts();
    this.productPerPage();
    this.productsFiltering();
    this._bindBtnFilter();
  }

  _bindBtnFilter() {
    this.elWrapper.on('click', '.btn-filter', (e) => {
      $('.products-filters').toggleClass('show');
      $('.bg-opac').toggleClass('show');            
    });

    this.elWrapper.parent().on('click', '.btn-close', (e) => {
      $('.products-filters').removeClass('show');
      $('.bg-opac').removeClass('show');     
    });

  }
   

  moreProducts() {
    this.elWrapper.on('click', '#next, #next-big', (e) => {
      e.preventDefault();
        window.product_filter.current_page = window.product_filter.current_page || 1;
        window.product_filter.current_page++;
        let catClick = '';
        this.cleanAndAddProducts(catClick);
    });
    this.elWrapper.on('click', '#prev, #prev-big', (e) => {
        e.preventDefault();
        window.product_filter.current_page = window.product_filter.current_page  || 2 ;
        window.product_filter.current_page--;
        let catClick = '';
        this.cleanAndAddProducts(catClick);
    });
    this.elWrapper.on('click', '.current-page', (e) => {
        e.preventDefault();
        if($(e.target).hasClass('active-page')){
            e.stopPropagation();
        } else {            
            window.product_filter.current_page = parseInt($(e.target).attr('data-page'));
            let catClick = '';
            this.cleanAndAddProducts(catClick);
        }
    });
  }

  productPerPage() {
    var _this = this;
    $('select.number-select').each(function () {
      var $this = $(this),
        numberOfOptions = $(this).children('option').length;

      $this.addClass('s-hidden');
      $this.wrap('<div class="select select--product"></div>');

      var selectId = $this.attr('data-id');
      $this.after('<div id='+`${selectId}`+' class="styledSelect" data-target=""></div>');

      var $styledSelect = $this.next('div.styledSelect');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
          'class': 'options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val(),
        }).appendTo($list);
      }

      var $listItems = $list.children('li');

      $styledSelect.click(function (e) {
          e.stopPropagation();
          if($(this).hasClass('active')) {
              $(this).removeClass('active').next('ul.options').slideUp(200);
          } else {
              $(this).addClass('active').next('ul.options').slideDown(200);
          }
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        debugger;
        var target = $(this).attr('rel');
        $styledSelect.attr('data-target', `${target}`);
        $styledSelect.text($(this).text()).removeClass('active');
        $list.slideUp();
        var catClick = '';        
        _this.cleanAndAddProducts(catClick);
      });

      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.slideUp();
      });
    });
  }

  productsFiltering() {
    $('.products-shop-wrapper').on('click', '.filtered', (e) => {
        e.stopImmediatePropagation();   
        debugger;     
        let target = e.target;
        let parent = target.closest('li');
        let ulParent = parent.closest('ul');
        let catClick = $(ulParent).hasClass('prod-categories') ? 'click' : '';
        let siblings = $(parent).siblings('li');
        if($(parent).hasClass('selected')) {
            $(parent).removeClass('selected');
            $('.prod-sub-categories li').removeClass('selected');            
        } else {
            $(siblings).each((ind, elem) => {
              if($(elem).hasClass('selected')){
                  $(elem).removeClass('selected');
              }
            })
            $(parent).addClass('selected');
        }
        window.product_filter.current_page = 1 // reset start page        
        this.cleanAndAddProducts(catClick)
    });
  }

  fetchProducts() {
    var data = {
        'action': 'productfilter',
        'cat_click': window.product_filter.cat_click,
        'cat': window.product_filter.cat,
        'sub': window.product_filter.sub,
        'tag': window.product_filter.tag,
        'page' : window.product_filter.current_page,
        'max_per_page': window.product_filter.max_per_page,
        'nonce': $("[name='csrf-token']").attr("content"),
    };  
    $.ajax({
        url : window.product_filter.ajaxurl,
        data : data,
        dataType: 'json',        
        type : 'POST',
        success : function( data ){
            
            // if (data) {
            //   $('.products-list').hide().html(data.products_html).fadeIn(300);
            // }
            
            if( data ) {

                //get title
                let title = data.title;
                $('.top-products .title-product').html(title).fadeIn(300);                
                if (title != '') {
                  $('.top-products .title-product').removeClass('hidden');
                } else {
                  $('.top-products .title-product').addClass('hidden');
                  $('.top-products .title-product').hide();
                }
                                               
                if (data.subcats_html != "unchanged") {                  
                  $('.sub-categories-wrapper').remove();
                  $('.prod-categories li.selected').append(data.subcats_html);                  
                }
                

                //get products
                // let allProducts = data.products;
                // let prods = '';
                // if(allProducts.length !== 0) {
                //     $.each(allProducts, (ind, elem) => {
                //         prods += '<div class="product-card"><a href="'+ elem.permalink +'"><div class="prod-img"><img src="'+ elem.featured_image_url +'"></div><h4>'+ elem.post_title +'</h4>';
                //         if(elem.weight !== '' || elem.weight !== null) {
                //           prods += '<p>'+ elem.weight +'</p>';
                //         }
                //         prods += '</a></div>';
                //     })
                // } else {
                //     prods += '<div><p>Não foram encontrados produtos para os filtros escolhidos</p></div>';
                // }
                // $('.products-list').hide().html(prods).fadeIn(300);

                let prods = '';
                let allProducts = data.products_html;
                if(allProducts.length !== 0) {
                  prods += data.products_html;
                }else {
                  prods += '<div><p>Não foram encontrados produtos para os filtros escolhidos e a categoria ' + data.tag_name + '</p></div>';
                }
                $('.products-list').hide().html(prods).fadeIn(300);

                const pagination = data.pagination_html;
                $('.bottom-products .bottom-pagination').hide().html(pagination).fadeIn(300);
                $('.top-products .top-pagination').hide().html(pagination).fadeIn(300);                
            }
            
        },
    });
  }

  colectProductInfo(catClick) {
    window.product_filter.cat_click = catClick;
    window.product_filter.max_per_page = $('#number').attr('data-target');
    window.product_filter.cat = $('.prod-categories >li.selected').eq(0).attr('data-cat');
    window.product_filter.sub = (catClick == '' ? $('.prod-sub-categories .selected').attr('data-sub') : '');
    window.product_filter.tag = $('.prod-tag .selected').attr('data-tag');
    window.product_filter.current_page = window.product_filter.current_page || 1;

    let menuOption = 'cat';
    let menuValue = $('.prod-categories li.selected').attr('data-cat');

    if (window.product_filter.sub) {
      catClick = $('.prod-sub-categories .selected').attr('data-sub');
    }

    if  (window.product_filter.tag) {
      menuOption = 'tag';
      menuValue = $('.prod-tag .selected').attr('data-tag');
    }

    const url = `${location.protocol}//${location.host }${location.pathname}?opt=${menuOption}&val=${menuValue}&pg=${window.product_filter.current_page}`;   
    const state = {
      menu_option: menuOption,
      menu_value: menuValue
    };

    window.history.replaceState(state, "State 1", url);
    
  }

  

  cleanAndAddProducts(catClick) {    
    this.colectProductInfo(catClick);
    if(catClick != '' && catClick != null) {
      $('.prod-sub-categories').fadeOut(300);
    }
    $('.products-list').fadeOut(300);
    $('.top-products .title-product').fadeOut(300);
    $('.top-products .top-pagination').fadeOut(300);
    $('.bottom-products .bottom-pagination').fadeOut(300);
    $('.bottom-products .comand-btn').fadeOut(300);
    setTimeout(() => {
        this.fetchProducts();
    }, 300);
    $('body')[0].scrollIntoView();
  }

}