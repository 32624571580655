import $ from 'jquery'

export default class FoodWeel {
  // An example without jquery
  constructor($el) {      
    this.$el = $el;
    this._init();
    this._showWeel = this._showWeel.bind(this);
  }
  _init() {
    this._showWeel();
    let scrollOffset = $(document).scrollTop();
    let containerOffset = $('.food-weel').offset().top - window.innerHeight;
    if(scrollOffset > containerOffset) {
      this._weelAnimation();
    }
  }

  _showWeel() {
    $(document).on('scroll',(ev) => {
      let scrollOffset = $(document).scrollTop();
      let containerOffset = $('.food-weel').offset().top - window.innerHeight;
      if (scrollOffset > containerOffset) {
        setInterval(() => {
          this._weelAnimation();
        }, 200);
      }
    });
  }

  _weelAnimation() {
    $('.water-center').css('opacity', 1)
    const percentages = $.map(window.percentages_food_weel, (value, index) => { 
      return [parseInt(value, 10)]; 
    });

    percentages.sort((a, b) => {
      return a - b;
    }).reverse();

    let cumulativePercent_desktop = 5;
    let cumulativePercent_mobile = 5;
    let seconds = 500;

    $.each(percentages, ( index, value ) => {
      //For desktop
      let total_desktop = 1633;
      let dash_pie_desktop = (((value) * total_desktop) / 100) - 5;
      let space_pie_desktop = total_desktop - dash_pie_desktop;

      setTimeout(() => {
        $('.chart.desktop').css('opacity', 1);
        $(`.chart.desktop .pie-slice[data-pie=${index}]`).css({
          'strokeDasharray': `${dash_pie_desktop} ${space_pie_desktop}`,
          'stroke-dashoffset': -cumulativePercent_desktop,
        });
        $(`#text${index}`).css('opacity', 1);
        cumulativePercent_desktop += dash_pie_desktop + 5;
      }, seconds);

      //For mobile
      let total_mobile = 628;
      let dash_pie_mobile = (((value) * total_mobile) / 100) - 5;
      let space_pie_mobile = total_mobile - dash_pie_mobile;

      setTimeout(() => {
        $('.chart.mobile').css('opacity', 1);
        $(`.chart.mobile .pie-slice[data-pie=${index}]`).css({
          'strokeDasharray': `${dash_pie_mobile} ${space_pie_mobile}`,
          'stroke-dashoffset': -cumulativePercent_mobile,
        });
        $(`#text${index}`).css('opacity', 1);
        cumulativePercent_mobile += dash_pie_mobile + 5;
      }, seconds);

      seconds += 1000
    });
  }
}