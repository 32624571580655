import Swiper from 'swiper/bundle';
//import 'swiper/swiper-bundle.min.css';

export default class ReviewSlider {
  // An example without jquery
  constructor($el) {
      this.$el = $el;

      this._init()
  }
  _init() {
    const pagination = this.$el.querySelector('.swiper-pagination')
    const prev = this.$el.querySelector('.swiper-button-next')
    const next = this.$el.querySelector('.swiper-button-prev')
    const container = this.$el.querySelector('.swiper-container')
    this.review_slider = new Swiper(container, {
      loop: false,      
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: pagination,
      },
      navigation: {
        nextEl: prev,
        prevEl: next,
      },
      breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
        },
        970: {
          slidesPerView: 2,
        }
      }
    });
  }
}