import Swiper from 'swiper/bundle';
//import 'swiper/swiper-bundle.min.css';

export default class PageSlider {
  // An example without jquery
  constructor($el) {
      this.$el = $el;

      this._init()
  }
  _init() {
    const pagination = this.$el.querySelector('.swiper-pagination')
    const prev = this.$el.querySelector('.swiper-button-next')
    const next = this.$el.querySelector('.swiper-button-prev')
    const container = this.$el.querySelector('.swiper-container')
    this.slider = new Swiper(container, {
      spaceBetween: 10,
      loop: false,
      pagination: {
        el: pagination,
      },
      speed: 500,
      autoplay: {
        delay: 4000,        
      },
      navigation: {
        nextEl: prev,
        prevEl: next,
      },
      breakpoints: {
        320: {
          slidesPerView: 1.60,
          centeredSlides: true,
          initialSlide: 1,
        },
        480: {
          slidesPerView: 1.50,
          centeredSlides: true,
          initialSlide: 1,
        },
        // 640: {
        //   slidesPerView: 2,
        // },
        768: {
          spaceBetween: 20,
          slidesPerView: 1.50,
          centeredSlides: true,
          initialSlide: 1,
        },
        811: {
          spaceBetween: 20,
          slidesPerView: 1.50,
          centeredSlides: true,
          initialSlide: 1,
        },
        970: {
          spaceBetween: 20,
          slidesPerView: 2.15,
          centeredSlides: true,
          initialSlide: 1,
        },
        // 1280: {
        //   slidesPerView: 3,
        //   spaceBetween: 0,
        // },
        // 1600: {
        //   slidesPerView: 4,
        //   spaceBetween: 20,
        // }
      }
    });
  }
}