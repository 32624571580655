import Swiper from 'swiper/bundle';
//import 'swiper/swiper-bundle.min.css';

export default class ProductsSlider {
  // An example without jquery
  constructor($el) {
      this.$el = $el;

      this._init()
  }
  _init() {
    const pagination = this.$el.querySelector('.swiper-pagination')
    const prev = this.$el.querySelector('.swiper-button-next')
    const next = this.$el.querySelector('.swiper-button-prev')
    const container = this.$el.querySelector('.swiper-container')
    this.slider = new Swiper(container, {
      slidesPerView: 1,
      watchOverflow: true,
      pagination: {
        el: pagination,
      },
      navigation: {
        nextEl: prev,
        prevEl: next,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1580: {
          slidesPerView: 4,
        },
      },
      watchOverflow: true,

    });

  }

}